.bg-gradient {
  background: linear-gradient(45deg, #173567, #972868);
}

.bg-gradient-wave {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gradient-wave--whole {
  background-image: url(/images/background-whole.svg);
}

.bg-gradient-wave--minimal {
  background-image: url(/images/background-gradient-minimal.svg);
}

.c-breadcrumb-with-avatar {
  margin-top: 0;
}

.c-contact-form__form .c-form__body {
  margin-bottom: var(--g-spacing);
}

.c-contact-form__form .c-form__body .c-input__container {
  margin-bottom: var(--g-spacing);
}

.c-input {
  background-color: #fff;
}
.c-textarea {
  background-color: #fff;
}

.c-error-message {
  color: var(--g-color-red-400);
  font-size: var(--g-font-size-small);
  padding: 0 0 var(--g-spacing-small) 0;
}

.c-contact-form__form .c-form__footer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.c-container {
  margin-top: var(--g-spacing-x-large);
  margin-left: auto;
  margin-right: auto;
  max-width: 95.25rem;
  width: 86%;
}

.c-container--xs {
  max-width: 48rem;
}

.c-container--small {
  max-width: 62.5rem;
}

.c-container--large {
  max-width: 100rem;
}

.c-container--transition {
  transition: var(--g-transition-time) ease-in-out;
}

.c-copy-button:hover {
  cursor: pointer;
}

.c-input--is-error {
  border-color: var(--g-color-red-600);
}

.c-list-items {
  padding-left: 0;
}

.c-settings-button {
  border: 0;
}

.c-breadcrumb-item {
  margin-left: 0;
}

.c-settings-button:hover {
  cursor: pointer;
}

.c-text-snippet {
  padding: 0;
}

.c-ui-graphic {
  max-width: 100%;
  position: relative;
}

.c-page-preview-card--is-hero > .c-page-preview-card__title {
  font-size: 5rem;
  line-height: 5rem;
}

.ek-infobox {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-radius: 0 10px 10px 10px;
  display: flex;
  left: 0;
  margin: var(--spacing-12) 0;
  padding: var(--spacing-5) var(--spacing-8) var(--spacing-2) !important;
  position: relative;
  width: calc(100% - var(--spacing-2));
}

.ek-infobox .c-snippet__icon--left-top {
  align-items: center;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: calc(var(--spacing-12) * -1);
  left: -1px;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  padding: var(--spacing-2);
  width: var(--spacing-12);
}

.c-cms-container {
  display: flex;
  flex-direction: column;
}

.ek-theme-info {
  border-color: var(--burgundy-400) !important;
}

.ek-theme-info .c-snippet__icon--left-top {
  background-color: var(--burgundy-400) !important;
}

.ek-theme-example {
  border-color: var(--pear) !important;
}

.ek-theme-example .c-snippet__icon--left-top {
  background-color: var(--pear) !important;
}

.ek-theme-important {
  border-color: var(--monza) !important;
}

.ek-theme-important .c-snippet__icon--left-top {
  background-color: var(--monza) !important;
}

.ek-theme-tip {
  border-color: var(--pizazz) !important;
}

.ek-theme-tip .c-snippet__icon--left-top {
  background-color: var(--pizazz) !important;
}


.c-sidebar__container--left {
  /* height: 100%; */
  position: absolute;
  top: 4.5rem;
  z-index: 0;
  padding: 0;
}

.c-tablist__nav {
  padding: 0 var(--g-spacing-2x-large) 0 var(--g-spacing-small);
}

.c-left-sidebar__list {
  display: flex;
  flex-direction: column;
}

.c-left-sidebar__list-item {
  align-items: center;
  border-radius: var(--g-border-radius-small);
  display: flex;
  font-size: var(--g-font-size-small);
  gap: 0.85rem;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-left-sidebar__list-item--active {
  background-color: var(--g-color-grey-50);
}

.c-left-sidebar__list-item__icon {
  color: #cccfd9;
  height: 0.813rem;
  width: 0.813rem;
}

.c-sidebar__toggle-reveal-button {
  cursor: pointer;
  top: calc(var(--g-spacing-large) + 4.25rem) !important;
}

.c-app-header__end {
  padding-right: var(--g-spacing-x-small);
}


.l-container--is-align-left {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (min-width: 48em) {
  .ek-infobox {
    left: var(--spacing-12);
    margin-top: 0;
    width: calc(100% - var(--spacing-14));
  }

  .ek-infobox .c-snippet__icon--left-top {
    top: -1px;
    left: calc(var(--spacing-12) * -1);
    transform: rotate(270deg);
  }

  .ek-infobox .c-snippet__icon--left-top img {
    transform: rotate(90deg);
  }
}

@media (min-width: 64em) {
  .c-ui-graphic {
    position: absolute;
    right: 0;
    top: 50%;
    max-width: 42rem;
    transform: translate(40%, -40%);
  }

  .c-page-preview-card__container {
    width: 32.5%;
  }
}

@media (min-width: 81.25em) {
  .c-ui-graphic {
    transform: translate(0, -40%);
  }
}

.c-page-container {
  max-width: 125rem;
  margin: 0 auto;
}

.c-table-page-template .c-page-container > .l-container {
  display: block;
}

.c-tree-accordion__item-toggle {
  display: flex;
}

.c-tree-accordion__item-toggle .c-label__spacing  {
  margin-bottom: 0 !important;
}

.c-dropdown-navigation {
  z-index: 2;
}

.c-search-filter--wide .c-input {
  min-width: 17rem;
}

.c-button--primary {
  background-color: var(--purple-700);
  color: var(--g-color-white);
}

.c-play-button__icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}